import React, { useState, useEffect } from "react";
import Web3 from "web3";
import "./Mint.css";
import gif from "../../images/giphy-2.gif";
import btn from "../../images/Untitled design-67.png";
import { Link } from "react-router-dom";
import discord from "../../images/icon/discord-brands.svg";
import twitter from "../../images/icon/twitter-brands.svg";
import earth from "../../images/icon/plaanetIcon.png";

import marcoABI from "../../marcoABI.json";

require("dotenv").config();
const { REACT_APP_CONTRACT_MARCO } = process.env;

const Mint = () => {
  const [curr, setCurr] = useState(1);
  const [contract, setContract] = useState();
  const [wallet, setWallet] = useState();
  const [connectedAccount, setConnectedAccount] = useState("connect wallet");
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [supply, setSupply] = useState(1600);

  useEffect(() => {
    loadWeb3();
  }, []);

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      const web3 = window.web3;

      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }
      // await window.ethereum.enable();
      const ct = new web3.eth.Contract(marcoABI, REACT_APP_CONTRACT_MARCO);
      setContract(ct);
      const sup = await ct.methods.totalSupply().call();
      setSupply(supply - sup);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function connect() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      setWallet(metaMaskAccount[0]);
      let splitedMetaMaskAddress;
      if (metaMaskAccount) {
        splitedMetaMaskAddress =
          metaMaskAccount[0].substring(0, 6) +
          "......" +
          metaMaskAccount[0].substring(
            metaMaskAccount[0].length - 4,
            metaMaskAccount[0].length
          );
      }
      setConnectedAccount(splitedMetaMaskAddress);
      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }
      // setConnected(true);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function mint() {
    setError("");
    setSuccess("");
    try {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      const price = await contract.methods.cost().call();
      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }
      await contract.methods
        .mint(curr)
        .send({ from: metaMaskAccount[0], value: price * curr });
      setSuccess("Mint Successfully");
    } catch (error) {
      setError("Mint Failed");
    }
  }

  return (
    <>
      <div className="header">
        <a
          href="https://www.silkroadgang.com/"
          className="menu"
          target="_blank"
        >
          Home
        </a>
        <a href="http://club.silkroadgang.com" className="menu">
          Lazy Lounge Club
        </a>
        <a onClick={() => connect()} className="menu" target="_blank">
          {connectedAccount}
        </a>
      </div>
      <div style={{ height: "100vh" }} className="container">
        <div className="mint_box">
          <div className="header-cont">
            <div class="header_flex">
              <div class="dutch-detail-two-sides">
                <div class="dutch-det-left">
                  <div class="text-block-13">Supply</div>
                </div>
                <div class="dutch-det-right">
                  <div class="text-block-12">{supply}</div>
                </div>
              </div>
              <div class="dutch-detail-two-sides">
                <div class="dutch-det-left">
                  <div class="text-block-13">Price</div>
                </div>
                <div class="dutch-det-right">
                  <div class="text-block-12">100 MATIC</div>
                  {/* <div class="text-block-12">Whitelist Only</div> */}
                </div>
              </div>
              <div class="dutch-detail-two-sides">
                <div class="dutch-det-left">
                  <div class="text-block-13">Max</div>
                </div>
                <div class="dutch-det-right">
                  <div class="text-block-12">5 per Wallet</div>
                  {/* <div class="text-block-12">Whitelist Only</div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="main_box">
            <div className="box_1">
              <div className="sub_box_1">
                <img src={gif} alt="" width="110px" />
              </div>
              <div className="sub_box_1">
                <p>Price Per NFT</p>
                <h2>100 MATIC Each</h2>
              </div>
            </div>
            <div className="box_1">
              <div className="sub_box_1">
                <button
                  onClick={() => {
                    if (curr > 0) {
                      setCurr(curr - 1);
                    }
                  }}
                >
                  -
                </button>{" "}
                <span style={{ fontSize: "2rem", margin: "0 5px" }}>
                  {curr}
                </span>{" "}
                <button
                  onClick={() => {
                    if (curr < 5) {
                      setCurr(curr + 1);
                    }
                  }}
                >
                  +
                </button>
              </div>
              <div className="sub_box_1">
                <button
                  className="btn"
                  onClick={() => setCurr(5)}
                  style={{ fontSize: "1.1rem", borderRadius: "8px" }}
                >
                  SET MAX
                </button>
              </div>
            </div>
            <div className="box_1">
              <div className="sub_box_1">
                <p>Total</p>
              </div>
              <div className="sub_box_1">
                <p>{curr * 100} Matic</p>
              </div>
            </div>
            <div className="mint_btn">
              <button className="btn" onClick={() => mint()}>
                MINT Marco Polo
              </button>
              <br />
              <h2>{success}</h2>
              <h2>{error}</h2>
            </div>
          </div>
          <div className="bottomtext">
            <h1>Welcome to the Silk Road Gang !</h1>
            <p>
              It was 2022 when Marco Polo genesis pulled together a rag-tag
              group company of adventurers who set sail, embarking on an epic
              quest to find and restore this fabled route. A unique collection
              of 1600 NFTs of Marco Polo genesis its ready to MINT
            </p>
            <p style={{ fontWeight: "900", fontSize: "1.3rem" }}>
              Holding 2 Marco Polo genesis and 600 $ilk, you can mint Khubilai
              Khaan or other gang utility. Each owner of a Marco Polo will be
              able to make their coin produce 10 $ilk per day. $ilk can be used
              to buy products, trade and stake.
            </p>
            <p>
              Exploring Blockchain deep corners they spotted a mythical Club and
              called it Lazy Lounge Club. It was a place to trade and meet. By
              quick discovery of many efficient resources the great decision has
              been made to create here the Marketplace 3.0. $ilk token can be
              used to buy or trade real products !
            </p>
            <p style={{ fontWeight: "900", fontSize: "1.3rem" }}>
              CHECK on DISCORD the list of companies that have made their
              products/services available to holders of our NFT !
            </p>
            <p>
              This collection will be released in total of two batches: <br />
              I. Private Sale [WhiteList] - 200 NFTS | Price: 30 MATIC | Date:
              12/03/2022 <br />
              II. Public Sale - 1400 NFTS | Price: 100 MATIC | Date: TBA <br />
              After Minting event all NFTs will be listed on all secondary
              marketplaces !
            </p>
            <p style={{ fontWeight: "900", fontSize: "1.3rem" }}>
              JOIN Silk Road Gang DISCORD CHANNEL to ENTER in WhiteList! <br />
              ⭐️⭐️⭐️ <br />
              Marco Polo genesis is only available in 1600 pieces. Each Marco
              Polo is a unique piece. The traits include 4 facial expressions +
              a special one, 5 backgrounds, 5 different clothes, 2 hats and 6
              facial accessories. There will also be 70 rarities.
            </p>
          </div>
        </div>
        <div>
          <ul class="iconsDesign">
            <li>
              <a href="https://discord.gg/7RkCDP6vHU" target="_blank">
                <img src={discord} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/silkroadgang" target="_blank">
                <img src={twitter} alt="" />
              </a>
            </li>
            <li>
              <a href="https://silkroadgang.com" target="_blank">
                <img src={earth} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="right_sec">
        <Link to="/marcopolo">
          <img src={btn} alt="" />
        </Link>
      </div>
    </>
  );
};

export default Mint;
