import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Mint from "./components/Mint/Mint";
import Macropolo from "./components/Macropolo/Macropolo";
function App() {
  return (
    <Router>
      {/* <div className="header">
        <a href="https://www.silkroadgang.com/" className='menu' target="_blank">Home</a>
        <a href="http://club.silkroadgang.com" className='menu'>Lazy Lounge Club</a>
        <a href="#" className='menu' target="_blank">Connect Wallet</a>
      </div> */}
      <Routes>
        <Route path="/" element={<Mint />} />
        <Route path="/marcopolo" element={<Macropolo />} />
      </Routes>
    </Router>
  );
}

export default App;
