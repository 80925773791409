import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { ethers } from "ethers";
import gif from "../../images/kublai khon.gif";
import btn from "../../images/Untitled design-68.png";
import { Link } from "react-router-dom";
import "./Macropolo.css";
import discord from "../../images/icon/discord-brands.svg";
import twitter from "../../images/icon/twitter-brands.svg";
import earth from "../../images/icon/plaanetIcon.png";
import eyeOpen from "../../images/eye_open.svg";
import eyeClose from "../../images/eye_close.svg";

import marcoABI from "../../marcoABI.json";
import kubABI from "../../kublaiABI.json";
import tokenABI from "../../tokenABI.json";

require("dotenv").config();
const {
  REACT_APP_CONTRACT_MARCO,
  REACT_APP_CONTRACT_KUBLAI,
  REACT_APP_CONTRACT_TOKEN,
} = process.env;

const Macropolo = () => {
  const [contract, setContract] = useState();
  const [kubContract, setKubContract] = useState();
  const [tokenContract, setTokenContract] = useState();
  const [nftInWallet, setNftInWallet] = useState(0);
  const [connectedAccount, setConnectedAccount] = useState("connect wallet");
  const [wallet, setWallet] = useState();
  const [tokens, setTokens] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [connected, setConnected] = useState(false);
  const [supply, setSupply] = useState(800);

  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    loadWeb3();
  }, []);

  async function loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      // await window.ethereum.enable();
      const web3 = window.web3;

      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }

      const ct = new web3.eth.Contract(marcoABI, REACT_APP_CONTRACT_MARCO);
      setContract(ct);
      const ct1 = new web3.eth.Contract(kubABI, REACT_APP_CONTRACT_KUBLAI);
      setKubContract(ct1);
      const ct2 = new web3.eth.Contract(tokenABI, REACT_APP_CONTRACT_TOKEN);
      setTokenContract(ct2);
      const sup = await ct1.methods.totalSupply().call();
      setSupply(supply - sup);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function connect() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      setWallet(metaMaskAccount[0]);
      let splitedMetaMaskAddress;
      if (metaMaskAccount) {
        splitedMetaMaskAddress =
          metaMaskAccount[0].substring(0, 6) +
          "......" +
          metaMaskAccount[0].substring(
            metaMaskAccount[0].length - 4,
            metaMaskAccount[0].length
          );
      }
      let balance = await contract.methods.balanceOf(metaMaskAccount[0]).call();
      setNftInWallet(balance);
      let tokens = await tokenContract.methods
        .balanceOf(metaMaskAccount[0])
        .call();
      setTokens(tokens);
      setConnectedAccount(splitedMetaMaskAddress);
      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }
      setConnected(true);
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider);
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  async function mint() {
    setError("");
    setSuccess("");
    try {
      window.web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const web3 = window.web3;
      const metaMaskAccount = await web3.eth.getAccounts();
      const chainIdHex = web3.currentProvider.chainId;
      if (chainIdHex != "0x89") {
        await web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x89" }],
        });
      }
      // const amount = new web3.utils.BN(600);
      // let amount = 600 * Math.pow(10, 18);
      let amount = ethers.BigNumber.from("600000000000000000000");
      // console.log(amount);

      await tokenContract.methods
        .approve(REACT_APP_CONTRACT_KUBLAI, amount)
        .send({ from: metaMaskAccount[0] });
      await kubContract.methods.mint(600).send({ from: metaMaskAccount[0] });
      setSuccess("Mint Successfully");
    } catch (error) {
      setError("Mint Failed");
    }
  }

  function toggleWallet() {
    setHidden(!hidden);
  }

  return (
    <>
      <div className="header">
        <a
          href="https://www.silkroadgang.com/"
          className="menu"
          target="_blank"
        >
          Home
        </a>
        <a href="http://club.silkroadgang.com" className="menu">
          Lazy Lounge Club
        </a>
        <a onClick={() => connect()} className="menu" target="_blank">
          {connectedAccount}
        </a>
      </div>
      <div className="right_sec_macro">
        <Link to="/">
          <img src={btn} alt="" />
        </Link>
      </div>
      <div style={{ height: "100vh" }} className="container">
        <div className="mpbox">
          <div className="DinnerBox">
            <h1 style={{ marginBottom: "2rem" }}>
              {" "}
              <span className="title">
                Wallet ID:{" "}
                <img
                  src={!hidden ? eyeOpen : eyeClose}
                  width="20"
                  onClick={() => toggleWallet()}
                />
                <span
                  style={{
                    fontSize: "30px",
                    marginLeft: "20px",
                  }}
                >
                  {!hidden ? wallet : "***********************************"}
                </span>
              </span>
            </h1>
            <h1>
              {" "}
              <span className="title">
                Total Kublai Khan Supply: {supply}
              </span>{" "}
            </h1>
            <h1>
              {" "}
              <span className="title"> Marco Polo: {nftInWallet}</span>
            </h1>
            <h1>
              {" "}
              <span className="title">
                Total balance in wallet: {tokens / Math.pow(10, 18)} $ilk
              </span>{" "}
            </h1>
            <h1 className="title">
              You need 2 Marco Polo to Make Kublai Khan and 600 $ilk, You will
              not lose your Marco Polo
            </h1>
          </div>
          <div className="mp_lower">
            <img src={gif} alt="" width="150px" />
            {!connected ? (
              <button onClick={() => connect()}>Connect Wallet</button>
            ) : tokens / Math.pow(10, 18) < 600 || nftInWallet < 2 ? null : (
              <button onClick={() => mint()}>MAKE kublai Khan</button>
            )}

            <h2>{success}</h2>
            <h2>{error}</h2>
            <div className="bottomtext" style={{ textAlign: "center" }}>
              <p>
                Holding 2 Marco Polo genesis and burning 600 $ilk, you can mint
                Khubilai Khaan. <br /> <br />
                Each owner of a Marco Polo will be able to make their coin
                produce 10 $ilk per day. <br />
                <br />
                $ilk can be used to buy products, trade real products ! CHECK on
                DISCORD the list of companies that have made their
                products/services available to holders of our NFT !
              </p>
            </div>
          </div>
        </div>
        <div>
          <ul class="iconsDesign">
            <li>
              <a href="https://discord.gg/7RkCDP6vHU" target="_blank">
                <img src={discord} alt="" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/silkroadgang" target="_blank">
                <img src={twitter} alt="" />
              </a>
            </li>
            <li>
              <a href="https://silkroadgang.com" target="_blank">
                <img src={earth} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Macropolo;
